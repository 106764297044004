import React from "react"
import { graphql } from "gatsby"
import Layout from "./../components/layouts/Default"
import Breadcrumb from "../components/common/Breadcrumb"
import Container from "../components/layouts/container/Container"
import ColumnsThree from "../components/layouts/container/ColumnsThree"
import SectionTitle from "../components/common/SectionTitle"
import HeaderImage from "../components/common/HeaderImage"
import BlurbKontakt from "../components/common/BlurbKontakt"
import MainData from "../components/forms/MainData"

export default function Page({ data, location }) {
  const svg = {
    phone: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
        />
      </svg>
    ),
    mail: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    location: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-16 h-16 mx-auto text-brand"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  }

  return (
    <Layout
      location={location}
      title="Kontakt | Döbbel Immobilien"
      name="Der Weg zu uns"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb links={[{ link: "Der Weg zu uns", to: "/kontakt/" }]} />
      <Container className="my-8 md:my-16">
        <div>
          <SectionTitle
            title="Kontaktdaten"
            subtitle="Der Weg zu uns"
            text="Rufen Sie uns an oder schreiben Sie uns eine E-Mail."
            className="mx-auto mb-8 text-center "
          />
        </div>
        <ColumnsThree>
          <a href={`tel:${data.site.siteMetadata.contact.phone}`}>
            <BlurbKontakt svg={svg.phone} category="Telefon">
              {data.site.siteMetadata.contact.phone}
            </BlurbKontakt>
          </a>
          <a href="https://www.google.co.in/maps/place/Magdeburg-Leipziger-Stra%C3%9Fe+127,+39443+Sta%C3%9Ffurt/@51.9000732,11.6310763,16.5z/data=!4m5!3m4!1s0x47a5ef10460e486b:0x5a7f4fbb4a365fbf!8m2!3d51.90127!4d11.62985">
            <BlurbKontakt svg={svg.location} category="Adresse">
              {data.site.siteMetadata.contact.street},<br />
              {data.site.siteMetadata.contact.plz}{" "}
              {data.site.siteMetadata.contact.city}
            </BlurbKontakt>
          </a>
          <a href={`mailto:${data.site.siteMetadata.contact.mail}`}>
            <BlurbKontakt svg={svg.mail} category="E-Mailadresse">
              {data.site.siteMetadata.contact.mail}
            </BlurbKontakt>
          </a>
        </ColumnsThree>
      </Container>
      <Container className="my-8 md:my-16">
        <div className="max-w-3xl p-5 mx-auto rounded shadow">
          <SectionTitle
            title="Kontaktformular"
            subtitle="Der Weg zu uns"
            text="Hinterlassen Sie uns eine Nachricht."
            className="mx-auto mb-8 text-center "
          />
          <form
            name="Kontakt"
            method="POST"
            data-netlify="true"
            action="/nachricht-gesendet/"
          >
            <input type="hidden" name="form-name" value="Kontakt" />

            <MainData />
            <button
              className="inline-flex items-center justify-center w-full px-3 py-3 text-lg text-center text-white transition duration-300 ease-in-out border border-transparent rounded shadow btn bg-brand hover:bg-gray-800 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              <svg
                className="-ml-0.5 mr-2 h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              Jetzt senden
            </button>
          </form>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "kontakt.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        contact {
          city
          phone
          plz
          street
          mail
        }
      }
    }
  }
`
