import React from "react"

const ColumnsThree = ({ children, className }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-3 gap-8 ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  )
}

export default ColumnsThree
