import React from "react"
import Input from "./Input"
import CheckboxCard from "./CheckboxCard"

const FormMainData = props => {
  const svg = {
    morgens: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
        <line x1="3" y1="21" x2="21" y2="21" />
        <path d="M12 9v-6l3 3m-6 0l3 -3" />
      </svg>
    ),
    mittags: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="4" />
        <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
      </svg>
    ),
    abends: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
        <line x1="3" y1="21" x2="21" y2="21" />
        <path d="M12 3v6l3 -3m-6 0l3 3" />
      </svg>
    ),
  }

  return (
    <React.Fragment>
      <div>
        <div className="mt-8 mb-4">
          <h3 className="text-lg font-bold leading-6 text-gray-900">
            Kontaktdaten
          </h3>
          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
            Diese Informationen sind wichtig, damit wir Sie kontaktieren können.
          </p>
        </div>
        <div className="grid-cols-2 gap-4 sm:grid ">
          <div className="mb-4">
            <Input
              type="text"
              label="Vorname"
              id="vorname"
              name="Vorname"
              placeholder="Ihr Vorname"
              required
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Nachname"
              id="nachname"
              name="Nachname"
              placeholder="Ihr Nachname"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <Input
            type="tel"
            label="Telefon"
            id="telefon"
            name="Telefon"
            placeholder="Ihre Telefonnummer"
            autoComplete="tel"
            inputMode="numeric"
            required
          />
        </div>
        <div className="mb-4">
          <Input
            type="email"
            label="E-Mail"
            id="email"
            name="E-Mail"
            placeholder="Ihre E-Mailadresse"
            autoComplete="email"
            required
          />
        </div>
      </div>

      <div>
        <div className="mt-8 mb-4">
          <h3 className="text-lg font-bold leading-6 text-gray-900">
            Wann sind Sie erreichbar?
          </h3>
          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
            Diese Informationen sind wichtig, damit wir Sie nicht ungewollt
            stören.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-3">
          <CheckboxCard
            label="Morgens"
            name="Erreichbar-Morgens"
            value="Ja"
            id="erreichbarkeit-morgens"
            svg={svg.morgens}
          />

          <CheckboxCard
            label="Mittags"
            name="Erreichbar-Mittags"
            value="Ja"
            id="erreichbarkeit-mittags"
            svg={svg.mittags}
          />

          <CheckboxCard
            label="Nachmittags"
            name="Erreichbar-Nachmittags"
            value="Ja"
            id="erreichbarkeit-nachmittags"
            svg={svg.abends}
          />
        </div>
      </div>

      <div>
        <div className="mt-8 mb-1">
          <h3 className="text-lg font-bold leading-6 text-gray-900">
            Haben Sie was auf dem Herzen?
          </h3>
          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
            Teilen Sie uns mit falls Sie noch was zu sagen haben.
          </p>
        </div>

        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-900"
            htmlFor="kommentar"
          >
            Kommentar
          </label>
          <textarea
            cols="50"
            rows="4"
            className="w-full p-4 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="kommentar"
            name="Kommentar"
          />
        </div>

        <div>
          <p className="mt-3 mb-6 text-sm leading-5">
            Wir respektieren Ihr Vertrauen und behandeln Ihre Daten mit Respekt.
            Mit dem Absenden dieses Formulars akzeptieren Sie unsere{" "}
            <a
              href="/datenschutz/"
              className="font-medium underline"
              target="_blank"
            >
              Datenschutzerklärung
            </a>
            .
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FormMainData
