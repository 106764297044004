import React from "react"
import Img from "gatsby-image"

const HeaderImage = ({ img }) => {
  return (
    <div className="relative h-48">
      <Img
        fluid={img}
        className="absolute inset-0 object-cover w-full h-full"
      />

      <div className="absolute inset-0 object-cover w-full h-full bg-white bg-opacity-50"></div>
    </div>
  )
}

export default HeaderImage
