import React from "react"

const SectionTitle = ({ subtitle, title, text, className }) => {
  return (
    <div className={`max-w-2xl ${className && className}`}>
      <h2 class="text-base font-semibold text-brand uppercase tracking-wide">
        {subtitle}
      </h2>
      <h2 class="mt-2 text-3xl font-extrabold text-gray-900">{title}</h2>
      <p class="mt-4 text-lg text-gray-500">{text}</p>
    </div>
  )
}

export default SectionTitle
