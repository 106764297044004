import React from "react"

const BlurbKontakt = ({ svg, category, children }) => {
  return (
    <div className="p-5 transition-shadow duration-300 rounded shadow hover:shadow-xl target:shadow-xl">
      <div className="mb-4 text-center">
        <div className="inline-block">{svg}</div>
      </div>
      <div className="text-center">
        <h2 className="mb-3 text-2xl font-bold">{category}</h2>
        <p className="">{children}</p>
      </div>
    </div>
  )
}

export default BlurbKontakt
